<template>
    <div class="block questions">
        <div class="block-header">
            <h3 class="block-title">Список вопросов для тестирования</h3>
            <div class="questions__filter">
                <v-select2
                    placeholder="Все отделы"
                    id="id_structure"
                    v-model="filter.structure"
                    label="name"
                    :reduce="name => name.id"
                    :options="structureList" :clearable="true">
                    <span slot="no-options">Ничего не найдено</span>
                </v-select2>
            </div>
            <div>
                <a
                    class="btn btn-rounded btn-primary-dark questions__add-button"
                    @click="openQuestionModal(null, null)"
                >
                    + Добавить вопрос
                </a>
            </div>
        </div>
        <div class="row gutters-tiny">
            <div class="block-content">
                <div class="department-table" v-if="results.length > 0">
                    <div class="department-table__block" v-for="structure in results" :key="`structure_${structure.id}`"
                         :item="structure">
                        <div class="department-table__row department-table__row--header">
                            <div
                                @click="openQuestionsListModal(structure, null)"
                                class="department-table__cell department-table__cell--title department-table__cell--header-title">
                                {{structure.name}}
                            </div>
                            <div class="department-table__cell department-table__cell--questions">
                                {{structure.count_question}}
                            </div>
                            <div class="department-table__cell department-table__cell--switch">
                                <div class="attestation-switch" @click="toggleStatusMenu(`structure_${structure.id}`)"
                                     :class="{
                                     'open': activeMenuStatus === `structure_${structure.id}`,
                                     'done': structure.is_access_testing,
                                     'warning': !structure.is_access_testing}">
                                    <div class="attestation-switch__item done" data-status="done"
                                         @click="switchStatus(structure, null)">
                                        <span class="attestation-switch__done-icon"></span>
                                        <span class="attestation-switch__txt">Тестирование настроено</span>
                                    </div>
                                    <div class="attestation-switch__item warning" data-status="warning"
                                         @click="switchStatus(structure, null)">
                                        <span class="attestation-switch__warning-icon"></span>
                                        <span class="attestation-switch__txt">Тестирование не настроено</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="department-table__row" v-for="position in structure.positions"
                             :key="`position_${position.id}`" :item="position">
                            <div @click="openQuestionsListModal(structure, position)"
                                 class="department-table__cell department-table__cell--title">
                                {{position.name}}
                            </div>
                            <div class="department-table__cell department-table__cell--questions">
                                {{position.count_question}}
                            </div>
                            <div class="department-table__cell department-table__cell--switch">
                                <div class="attestation-switch" @click="toggleStatusMenu(`structure_${structure.id}_position_${position.id}`)"
                                     :class="{
                                     'open': activeMenuStatus === `structure_${structure.id}_position_${position.id}`,
                                     'done': position.is_access_testing,
                                     'warning': !position.is_access_testing}">
                                    <div class="attestation-switch__item done"
                                         @click="switchStatus(structure, position)">
                                        <span class="attestation-switch__done-icon"></span>
                                        <span class="attestation-switch__txt">Тестирование настроено</span>
                                    </div>
                                    <div class="attestation-switch__item warning"
                                         @click="switchStatus(structure, position)">
                                        <span class="attestation-switch__warning-icon"></span>
                                        <span class="attestation-switch__txt">Тестирование не настроено</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    v-if="!isLoading && results.length === 0"
                    class="content content-full"
                >
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-md-7 col-sm-10">
                            <div class="attestation-empty">
                                <div
                                    v-if="!isLoading && materialsExists"
                                    class="attestation-empty__left"
                                >
                                    <h1 class="attestation-empty__title">
                                        Нет вопросов для тестирования
                                    </h1>

                                    <div class="attestation-empty__subtitle">
                                        Пожалуйста, добавьте вопросы на
                                        проверку знаний сотрудников правил из базы знаний.
                                    </div>
                                    <a
                                        v-if="current_user.is_editor_testing"
                                        @click="openQuestionModal(null, null)"
                                        class="btn btn-rounded btn-primary-dark questions__add-button"
                                    >
                                        <i class="fa fa-plus mr-5"></i>
                                        Добавить вопрос
                                    </a>
                                </div>

                                <div
                                    v-if="!isLoading && !materialsExists"
                                    class="attestation-empty__left"
                                >
                                    <h1 class="attestation-empty__title">
                                        Нет статей для тестирования
                                    </h1>

                                    <div class="attestation-empty__subtitle">
                                        Пожалуйста, добавьте статьи в базу
                                        знаний для проверки сотрудников.
                                    </div>

                                    <router-link
                                        v-if="current_user.is_editor_testing"
                                        tag="a"
                                        to="/knowledge/add"
                                        class="btn btn-rounded btn-primary-dark"
                                    >
                                        <i class="fa fa-plus mr-5"></i>
                                        Добавить статью
                                    </router-link>
                                </div>

                                <div class="attestation-empty__right">
                                    <div class="attestation-empty__img">
                                        <img
                                            src="~assets/img/favicons/doc_not_ready.svg"
                                            alt="Нет статей"
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <loading-spinner
                    v-if="isLoading"
                    class="wall__spinner"
                />
                <mugen-scroll
                    :handler="infiniteScrollHandler"
                    :should-handle="!isLoading"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import session from '@/api/session';
    import MugenScroll from 'vue-mugen-scroll'
    import { mapState } from "vuex";

    export default {
        name: "QuestionsBlock",
        components: {
            MugenScroll,
            LoadingSpinner : () => import('@/components/LoadingSpinner')
        },
        data() {
            return {
                activeMenuStatus: '',
                nextData: '/api/v1/question/main_list/',
                isLoading: false,
                results: [],
                filter: {
                    structure: null
                }
            }
        },
        watch: {
            'filter.structure': {
                handler: function (val, oldVal) {
                    if (val !== oldVal) {
                        this.nextData = '/api/v1/question/main_list/';
                        this.results = [];
                        this.isLoading = true;
                        this.loadList()
                    }
                },
                deep: true
            }
        },
        props: {
            materialsExists: {
                type: Boolean,
                required: false
            },
            structureList: {
                type: Array,
                required: false
            }
        },
        computed: {
            ...mapState('default_data', [
                'current_user'
            ]),
        },
        created() {
            this.loadList()
        },
        mounted() {
            document.addEventListener('click', this.checkTargetClick);
        },
        beforeDestroy() {
            document.removeEventListener('click', this.checkTargetClick);
        },
        methods: {
            checkTargetClick(event) {
                if (!event.target.classList.contains('attestation-switch')) {
                    this.toggleStatusMenu();
                }
            },
            toggleStatusMenu(id=null) {
                this.activeMenuStatus = this.activeMenuStatus === id ? null : id
            },
            async switchStatus(structure, position) {
                try {
                    let data = {}
                    if (position) {
                        position.is_access_testing = !position.is_access_testing;
                        data = {
                            structure_id: structure.id,
                            position_id: position.id,
                            access: position.is_access_testing
                        }
                    } else {
                        structure.is_access_testing = !structure.is_access_testing;
                        data = {
                            structure_id: structure.id,
                            access: structure.is_access_testing
                        }
                    }
                    const request = session.post('/api/v1/testing_access/', data);
                    let response = await request;
                    this.activeMenuStatus = null;
                } catch (err) {
                    console.error(err)
                }
            },
            infiniteScrollHandler() {
                if (this.results.length > 0) {
                    // Загрузка данных на страницу
                    if (!this.nextData) {
                        return false;
                    }
                    this.isLoading = true;

                    clearTimeout(this.timer);
                    this.timer = setTimeout(() => {
                        this.loadList();
                    }, 500);
                }
            },
            async loadList() {
                const params = {
                    id: this.filter.structure
                };

                if (this.nextData) {
                    await session
                        .get(this.nextData, { params })
                        .then(response => {
                            const data = response.data;

                            this.nextData = response.data.next;
                            this.results.push(...data.results);
                            this.isLoading = false;
                        })
                        .catch((err) => {
                            console.error(err)
                        });
                }
            },
            async openQuestionModal(material_id, question) {
                const isBlocked = this.isBlockedPortal();

                if (!isBlocked) {
                    const QuestionChangeModal = () => import(`../modals/QuestionChangeModal`);

                    this.$modal.show(QuestionChangeModal, {
                        material_id: material_id, question: question
                    }, {
                        name: 'question-change-modal',
                        scrollable: true,
                        height: 'auto',
                        adaptive: true,
                        maxWidth: 740,
                        width: '100%',
                        clickToClose: false
                    }, {
                        'before-close': (data) => {
                            if (data.params) {
                                this.nextData = '/api/v1/question/main_list/';
                                this.results = [];
                                this.loadList();
                            }
                        }
                    })
                }
            },
            async openQuestionsListModal(structure, position) {
                const QuestionsListModal = () => import(`../modals/QuestionsListModal`);
                this.$modal.show(QuestionsListModal, {
                    structureID: structure ? structure.id : null,
                    positionID: position ? position.id : null,
                    title: position ? position.name : structure.name,
                    countQuestion: position ? position.count_question : structure.count_question,

                }, {
                    name: 'questions-list-modal',
                    scrollable: true,
                    height: 'auto',
                    adaptive: true,
                    maxWidth: 740,
                    width: '100%',
                    clickToClose: false
                }, {
                    'before-close': (data) => {
                        if (data.params) {
                            // this.nextData = '/api/v1/question/main_list/';
                            // this.results = [];
                            // this.loadList();
                            // if (position) {
                            //     position.count_question = data.params.questionCount
                            // } else {
                            //     structure.count_question = data.params.questionCount
                            // }
                            this.results.forEach((structure) => {
                                session.get(`/api/v1/question/structure-question-count/${structure.id}/`)
                                    .then(response => {
                                        structure.count_question = response.data.count;
                                    });
                                structure.positions.forEach((position) => {
                                    session.get(`/api/v1/question/position-question-count/${position.id}/`)
                                    .then(response => {
                                        position.count_question = response.data.count;
                                    })
                                })
                            })
                        }
                    }
                })
            }
        }
    }
</script>

<style lang="scss">
    .block {
        position: relative;
    }

    .block .progress {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 8px;
        border-radius: 0;
        background-color: #f6f7f9;
        overflow: visible;
    }

    .block .progress .progress-meter {
        background-color: #fcc142;
        position: relative;
    }

    .block .progress .progress-meter-text {
        display: block;
        position: absolute;
        right: 0;
        top: -24px;
        transform: translateX(50%);
        margin-bottom: 0;
        font-size: 12px;
        line-height: 17px;
        word-break: normal;
    }

    .attestation-block {
        position: relative;
        padding: 7px 10px 20px;
        color: #000;
    }

    .attestation-block__progress {
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .attestation-block.attestation-block__inactive,
    .attestation-block.attestation-block__inactive * {
        color: #999;
    }

    .attestation-block__inactive .btn {
        pointer-events: none;
        opacity: 1;
        background-color: #eee;
        border-color: #eee;
        color: #b2b2b2;
    }

    .attestation-block__title time {
        font-size: 13px;
        line-height: 18px;
        color: #999;
        margin-left: 10px;
        font-weight: 400;
    }

    .block.questions .block-header {
        padding-top: 23px;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 20px;
        border-bottom: 1px solid #e8e8e8;
    }

    .block.questions .block-title {
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 26px;
    }

    .block.questions .block-content {
        padding-top: 26px;
        padding-left: 30px;
        padding-right: 30px;
    }

    @media screen and (max-width: 479px) {
        .block.questions .block-content {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    .block.questions .questions__header {
        text-transform: uppercase;
        font-size: 13px;
        line-height: 18px;
        list-style: 1px;
        font-weight: 600;
        color: #999;
    }

    .block.questions .questions__add-button {
        background-color: #fff;
    }

    .block.questions .questions__add-button:hover {
        background-color: #ffda67;
    }

    .questions-table {
        width: 100%;
        font-size: 1rem;
        line-height: 30px;
        color: #000;
        margin-bottom: 25px;
    }

    .questions-table tr {
        height: 60px;
        border-bottom: 1px solid #e8e8e8;
    }

    .questions-table td {
        padding: 0;
        border: none;
        vertical-align: middle;
    }

    .questions-table .questions-table__counter {
        width: 30px;
    }

    .questions-table .questions-table__buttons {
        text-align: right;
        width: 100px;
        color: #999;
    }

    .questions-table .button-edit,
    .questions-table .button-delete {
        display: inline-block;
        width: 26px;
        height: 26px;
        font-size: 0.875rem;
        line-height: 24px;
        text-align: center;
        color: #999;
        background-color: transparent;
        border: none;
        margin-left: 12px;
        cursor: pointer;
        border-radius: 13px;
        transition: all 0.2s ease;
    }

    .block-content .questions-table tr:hover .button-edit {
        background-color: #fcc142 !important;
        color: #fff !important;
    }

    .block-content .questions-table tr:hover .button-delete {
        background-color: #fc4c42 !important;
        color: #fff !important;
    }

    .question-permission {
        margin-top: 25px;
        padding: 12px 15px;
        background: #F4F4F4;
        font-size: 0.875rem;
        line-height: 19px;
        color: #222222;
    }

    .question-permission__link {
        display: block;
        float: right;
        font-weight: 600;
        font-size: 1rem;
        color: #2173D3;
    }

    .question-permission__link svg {
        display: inline-block;
        vertical-align: middle;
        margin-right: 4px;
        margin-top: -4px;
        fill: #2173D3;
    }

    @media screen and (max-width: 479px) {
        .question-permission__link {
            float: none;
            margin-top: 15px;
        }
    }

    .form_question .modal-footer {
        justify-content: center;
    }

    .question-warning {
        border: 2px solid #FBC04F;
        padding: 15px 20px;
        font-size: 1rem;
        line-height: 22px;
        color: #000000;
    }

    @media screen and (max-width: 575px) {
        .attestation-block {
            padding-left: 0;
            padding-right: 0;
        }
        .attestation-empty {
            display: block;
            margin-top: 50px;
            margin-bottom: 70px;
        }
        .attestation-empty__right {
            display: none;
        }
    }

    @media screen and (max-width: 767px) {
        .questions-table {
            line-height: 20px;
            font-size: 0.875rem;
        }
        .questions-table tr {
            height: 20px;
        }
        .questions-table .questions-table__counter {
            vertical-align: baseline;
        }
        .attestation-block__title time {
            display: block;
            margin-left: 0;
            margin-top: 5px;
        }
    }

    .custom-checkbox {
        position: relative;
        background-color: rgb(243, 243, 243);
        overflow: hidden;
        margin-bottom: 0;
        display: inline-flex;
        border-radius: 17px;
        -moz-user-select: none;
        -ms-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -webkit-touch-callout: none;
        cursor: pointer;
    }

    .custom-checkbox input[type="checkbox"] {
        position: absolute;
        opacity: 0;
    }

    .custom-checkbox__text-left,
    .custom-checkbox__text-right {
        display: block;
        padding-left: 15px;
        padding-right: 15px;
        border-radius: 17px;
        flex: 0 0 auto;
        color: rgb(138, 138, 138);
        font-size: 0.75rem;
        line-height: 34px;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .custom-checkbox input[type="checkbox"]:checked ~ .custom-checkbox__text-right {
        background-color: rgb(252, 193, 66);
        color: #000;
    }

    .custom-checkbox input[type="checkbox"]:not(:checked) ~ .custom-checkbox__text-right {
        background-color: transparent;
        color: rgb(138, 138, 138);
    }

    .custom-checkbox input[type="checkbox"]:checked ~ .custom-checkbox__text-left {
        background-color: transparent;
        color: rgb(138, 138, 138);
    }

    .custom-checkbox input[type="checkbox"]:not(:checked) ~ .custom-checkbox__text-left {
        background-color: rgb(252, 193, 66);
        color: #000;
    }

    .swal2-modal.custom-popup {
        text-align: left;
        padding: 40px 40px 45px !important;
        border-radius: 0;
    }

    .swal2-modal.custom-popup .swal2-title {
        margin-bottom: 34px;
        text-align: left;
        font-size: 1.25rem;
        line-height: 27px;
        color: #000;
    }

    .swal2-modal.custom-popup .swal2-title::after {
        content: "";
        display: table;
        clear: both;
    }

    .swal2-modal.custom-popup .swal2-title-left {
        float: left;
    }

    .swal2-modal.custom-popup .swal2-title-right {
        float: right;
    }

    @media screen and (max-width: 767px) {
        .swal2-modal.custom-popup .swal2-title-left {
            float: left;
        }
        .swal2-modal.custom-popup .swal2-title-right {
            float: right;
        }
    }

    .swal2-modal.custom-popup .swal2-content {
        text-align: left;
        font-size: 1rem;
        line-height: 20px;
        color: #000;
        font-weight: 400;
    }

    .employees-selection {
        font-size: 1rem;
        line-height: 18px;
        margin-bottom: 30px;
    }

    .employees-selection_left {
        border: 1px solid #e9e9e9;
        padding-bottom: 10px;
        min-height: 200px;
        height: 100%;
        max-height: 400px;
        overflow-y: auto;
    }

    .employees-selection_right {
        border: 1px solid #e9e9e9;
        padding-bottom: 10px;
        min-height: 200px;
        height: 100%;
        max-height: 400px;
        overflow-y: auto;
    }

    .employee-selection__heading {
        padding: 22px 40px 15px;
        font-size: 13px;
        line-height: 18px;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: #999;
    }

    .employees-selection__search {
        border-bottom: 1px solid #e9e9e9;
        margin-bottom: 10px;
    }

    .employees-selection__search .input-group-text {
        border-radius: 0;
        border: none;
        margin-right: 0;
        background-color: transparent;
        height: 40px;
    }

    .employees-selection__search .form-control {
        border-radius: 0;
        border: none;
        padding-left: 0;
        height: 40px;
    }

    .employees-selection__search .form-control:focus {
        border-color: #d4dae3;
    }

    .employees-selection__group .accordion__title {
        position: relative;
    }

    .employees-selection__group .group__button {
        position: absolute;
        right: 25px;
        top: 50%;
        transform: translateY(-50%);
        outline: none;
    }

    .employees-selection__group .accordion__title a {
        position: relative;
        padding-left: 40px;
        padding-right: 55px;
        display: block;
        line-height: 38px;
        font-weight: bold;
        color: #000;
    }

    .employees-selection__group .accordion__title a::before {
        content: "";
        position: absolute;
        left: 15px;
        top: 13px;
        transform: translateY(-50%);
        width: 9px;
        height: 9px;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        transform-origin: 75% 75%;
    }

    .employees-selection__group .accordion__title a.collapsed::before {
        transform: rotate(45deg);
    }

    .employees-selection__group .accordion__title a:not(.collapsed)::before {
        transform: rotate(-135deg);
    }

    .employees-selection__employee {
        line-height: 38px;
    }

    .employees-selection__employee .employee {
        font-weight: 400;
        position: relative;
        margin-bottom: 0;
        width: 100%;
    }

    .employees-selection__employee:first-child {
        /* margin-top: 10px; */
    }

    .employees-selection__employee .employee__checkbox {
        position: absolute;
        opacity: 0;
        left: -9999px;
        pointer-events: none;
    }

    .employee__name::before,
    .employee__name::after {
        position: absolute;
        content: "";
        background-color: #ccc;
    }

    .employee__name:hover::before,
    .employee__name:hover::after {
        background-color: #fcc142;
    }

    .employee__name::before {
        left: 38px;
        top: 11px;
        height: 14px;
        width: 2px;
    }

    .employee__name::after {
        left: 32px;
        top: 17px;
        height: 2px;
        width: 14px;
    }

    .employees-selection__employee .employee__name {
        display: block;
        line-height: 38px;
        padding-left: 55px;
        position: relative;
        cursor: pointer;
        user-select: none;
    }

    .employee__checkbox:checked ~ .employee__name::before {
        transform: rotate(-45deg);
        height: 8px;
        width: 2px;
        left: 34px;
        top: 16px;
        background-color: #000;
    }

    .employee__checkbox:checked ~ .employee__name::after {
        transform: rotate(-45deg);
        width: 11px;
        height: 2px;
        left: 36px;
        top: 17px;
        background-color: #000;
    }

    .employees__list {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .employees__list_item {
        line-height: 38px;
        padding-left: 40px;
        position: relative;
        cursor: pointer;
        user-select: none;
    }

    .employees__list_item::before,
    .employees__list_item::after {
        position: absolute;
        content: "";
        background-color: #ccc;
    }

    .employees__list_item:hover::before,
    .employees__list_item:hover::after {
        background-color: #d0021b;
    }

    .employees__list_item::before {
        transform: rotate(45deg);
        left: 21px;
        top: 12px;
        height: 14px;
        width: 2px;
    }

    .employees__list_item::after {
        transform: rotate(45deg);
        left: 15px;
        top: 18px;
        height: 2px;
        width: 14px;
    }

    .employees__list_person {

    }

    .employees__list_department {
        font-weight: bold;
    }

    .selection-settings {
        display: flex;
        flex-direction: column;
    }

    .selection-settings .js-datepicker {
        width: auto;
        display: inline-block;
        margin-left: 15px;
    }

    .datepicker-row {
        display: flex;
        margin-right: 150px;
    }

    .datepicker-input {
        flex: 0 0 180px;
    }

    .revalidation-popup {
        position: relative;
    }

    .revalidation-popup .swal2-buttonswrapper {
        position: absolute;
        bottom: 45px;
        right: 40px;
        margin-top: 0 !important;
    }

    @media screen and (max-width: 767px) {
        .employees-selection {
            margin-bottom: 40px;
        }
    }

    .swal2-overflow {
        overflow-x: visible;
        overflow-y: visible;
    }

    .group__button {
        cursor: pointer;
        position: relative;
        width: 22px;
        height: 22px;
        padding: 0;
        margin: 0;
        -webkit-appearance: none;
        appearance: none;
        outline: none;
        border: none;
        border-radius: 50%;
        box-shadow: none;
        background-color: #f5f5f5;
        color: #ccc;
    }

    .group__button:hover {
        color: #333;
        background-color: #ebebeb;
    }

    .group__icon::before,
    .group__icon::after {
        position: absolute;
        content: "";
        background-color: #ccc;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .group__button:hover .group__icon::before,
    .group__button:hover .group__icon::after {
        background-color: #333;
    }

    .group__icon::before {
        width: 10px;
        height: 2px;
    }

    .group__icon::after {
        width: 2px;
        height: 10px;
    }

    .group__button-active .group__icon::before,
    .group__button-active .group__icon::after {
        background-color: #333;
        transform: rotate(-45deg);
    }

    .group__button-active .group__icon::before {
        width: 11px;
        left: 35%;
    }

    .group__button-active .group__icon::after {
        height: 6px;
        left: 30%;
    }

    /* attestation styles*/
    .attestation-block {
        padding: 7px 20px 20px 10px
    }

    .attestation-block__title {
        font-size: 1.25rem;
        font-weight: bold;
        color: #000;
    }

    .attestation-block__title time {
        font-size: 13px;
        font-weight: normal;
        color: #999;
        letter-spacing: .5px;
        text-transform: uppercase;
    }

    .attestation-block__hint {
        margin-top: 11px;
        min-height: 42px;
        color: #000;
    }

    .attestation-block__buttons {
        margin-top: 20px;
    }

    .attestation-block__buttons .btn-alt-secondary {
        height: 38px;
        font-weight: bold;
        line-height: 36px;
        padding: 0 30px;
        color: #b2b2b2;
    }

    .attestation-block__buttons .reload-ls {
        height: 38px;
        padding: 0 20px;
        line-height: 36px;
        font-weight: bold;
    }

    .attestation-start {
        height: 38px;
        line-height: 36px;
        padding: 0 30px;
    }

    .attestation-block__buttons .attestation-close {
        color: #000;
    }

    .attestation-empty {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .attestation-empty__block {
        padding: 114px 20px 118px;
    }

    .attestation-empty__left {
        max-width: 330px;
    }

    .attestation-empty__title {
        font-size: 1.25rem;
        font-weight: bold;
        color: #000;
    }

    .attestation-empty__subtitle {
        margin-top: 11px;
        margin-bottom: 20px;
        color: #000;
    }

    .attestation-empty__right {
        max-width: 147px;
        margin-left: 40px;
    }

    .attestation-empty__img img {
        height: auto;
        width: 120px;
        display: block;
    }

    .title_create_question {
        font-size: 1.25rem;
        font-weight: bold;
        color: #000;
    }

    .form_question {
        font-size: 1rem;
    }

    .form_question .form-material.floating {
        padding-top: 0;
    }

    .form_question .form-control {
        font-size: 1rem;
    }

    .form_question label,
    .form_question .form-material > label,
    .form_question .form-material.floating.open > label {
        margin-bottom: 12px;
        font-size: 1rem;
        line-height: 20px;
        font-weight: normal;
        color: #999;
    }

    .form_question .form-material textarea,
    .form_question .form-material textarea:focus,
    .form_question .form-material textarea:active {
        padding: 8px 13px;
        border-radius: 2px;
        resize: none;
        height: 84px;
        min-height: auto;
        color: #000;
        border-color: #e9e9e9;
        box-shadow: none;
    }

    .form_question .questions-table tr {
        border: 1px solid #e9e9e9;
        height: 38px;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
    }

    .questions-table__text .form-control {
        height: 36px;
        color: #000;
        border: none;
    }

    .questions-table__text .form-control[disabled] {
        border: none;
    }

    .form_question .questions-table tbody {
        display: flex;
        flex-direction: column;
        margin-top: -15px;
    }

    .form_question .questions-table tbody tr {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        padding-right: 18px;
    }

    .form_question .form-material.floating label {
        position: static;
        font-size: 0.875rem;
    }

    .form_question .css-control-input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .form_question .css-control-input:focus ~ .css-control-indicator {
        box-shadow: none;
    }

    .form_question .css-checkbox .css-control-input ~ .css-control-indicator {
        display: block;
        width: 16px;
        height: 16px;
        margin: 0;
        border: none;
    }

    .form_question .css-checkbox .css-control-input ~ .css-control-indicator::after {
        content: "";
        display: block;
        width: 9px;
        height: 14px;
        border: 1px solid #cccccc;
        border-width: 0 1px 1px 0;
        transform: rotate(45deg);
    }

    .form_question .css-checkbox .css-control-input:checked ~ .css-control-indicator::after {
        border-color: #69ba10;
    }

    .form_question .css-checkbox.css-control-success .css-control-input:checked ~ .css-control-indicator {
        background: none;
    }

    .css-checkbox .css-control-input ~ .css-control-indicator {
        transition: background-color 0s;
    }

    .form_question .questions-table .questions-table__buttons {
        display: flex;
        align-items: center;
        width: auto;
    }

    .form_question .questions-table .questions-table__buttons label {
        display: none;
        padding: 0;
        margin: 0;
        font-size: 0;
        line-height: 16px;
    }

    .form_question .questions-table .button-delete {
        position: relative;
        width: 18px;
        height: 18px;
        padding: 0;
    }

    .form_question .questions-table .button-delete::before,
    .form_question .questions-table .button-delete::after {
        content: ' ';
        position: absolute;
        left: 8px;
        top: 0;
        height: 18px;
        width: 1px;
        background-color: #ccc;
    }

    .form_question .questions-table .button-delete::before {
        transform: rotate(45deg);
    }

    .form_question .questions-table .button-delete::after {
        transform: rotate(-45deg);
    }

    .form_question .questions-table .button-delete:hover:after,
    .form_question .questions-table .button-delete:hover:before {
        background-color: #fc4c42;
    }

    .questions-table tr:hover .button-delete {
        background: none;
    }

    .form_question .questions-table tbody tr:hover .questions-table__buttons label {
        font-size: 0.875rem;
        color: #69ba10;
    }

    .form_question .questions-table tbody tr:hover .css-checkbox .css-control-input ~ .css-control-indicator {
        position: relative;
        border-radius: 50%;
        border: 1px solid #69ba10;
        margin-right: 6px;
    }

    .form_question .questions-table tbody tr:hover .css-checkbox .css-control-input ~ .css-control-indicator::after {
        content: none;
    }

    .form_question .questions-table tbody tr:hover .css-checkbox.css-control-success .css-control-input:checked ~ .css-control-indicator {
        background: #69ba10;
    }

    .form_question .questions-table tbody tr:hover .css-checkbox.css-control-success .css-control-input:checked ~ .css-control-indicator:after {
        content: "";
        display: block;
        width: 6px;
        height: 9px;
        border: 1px solid #fff;
        border-width: 0 1px 1px 0;
        position: absolute;
        left: 4px;
        top: 1px;
        transform: rotate(45deg);
    }

    .form_question .add_one_question {
        height: 38px;
        font-weight: normal;
        background: #fff;
        border: 1px dashed #dcdcdc;
    }

    .form_question .modal-footer {
        border-top: none;
    }

    .form_question .questions-table__text {
        width: 60%;
    }

    .delete-question-header {
        padding: 22px 30px 0;
    }

    .question-form-header {
        padding: 40px 40px 20px;
    }

    .question-form-body {
        padding: 10px 40px 45px;
    }

    .reset-question-form {
        height: 38px;
        padding: 0 26px;
        color: #999999;
        background: none;
        border: none;
        font-weight: normal;
    }

    .save-question-form {
        font-weight: bold;
        padding: 0 26px;
        height: 38px;
    }

    .close-question-form-wrap {
        position: absolute;
        right: 0;
        top: 0;
        min-height: unset;
    }

    .form-material.floating.answer-choices {
        padding-top: 0;
    }

    @media (min-width: 576px) {
        .question-form-wrap {
            max-width: 646px;
        }

        .question-form-wrap_setting {
            max-width: 800px;
        }
        .delete-question-wrap {
            max-width: 460px;

        }
    }

    @media (max-width: 576px) {
        .close-question-form::before,
        .close-question-form::after {
            background-color: #ccc;
            opacity: 1;
        }

        .datepicker-row {
            flex-direction: column;
            margin-right: 0;
        }

        .selection-settings .js-datepicker {
            flex: 0 0 auto;
            margin-top: 10px;
            margin-left: 0;
        }

        .revalidation-popup .swal2-buttonswrapper {
            position: static;
            margin-top: 15px !important;
            text-align: right;
        }

        .swal2-modal.custom-popup .swal2-title-left,
        .swal2-modal.custom-popup .swal2-title-right {
            float: none;
            text-align: center;
        }

        .swal2-modal.custom-popup .swal2-title-right {
            margin-top: 15px;
        }

        .questions__filter {
            width: 100%;
            margin-right: 0;
            margin-bottom: 20px;
        }
    }

    /* РњРѕРґР°Р»СЊРЅРѕРµ РѕРєРЅРѕ СѓСЃРїРµС€РЅРѕР№ РїРµСЂРµР°С‚С‚РµСЃС‚Р°С†РёРё */
    .revalidation-success .swal2-title {
        display: none !important;
    }

    .revalidation-success__inner {
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }

    .revalidation-success__icon-wrap {
        max-width: 100px;
    }

    .revalidation-success__icon-wrap img {
        display: block;
        max-width: 100%;
    }

    .revalidation-success__text {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        max-width: 260px;
        margin-left: 35px;
    }

    .revalidation-success__title {
        font-size: 1.25rem;
        font-weight: bold;
        color: #000;
    }

    .revalidation-success__desc {
        margin-top: 5px;
        font-size: 0.875rem;
        color: #000;
    }

    .revalidation-success__close {
        margin-top: 20px;
        font-size: 1rem;
        font-weight: bold;
        padding: 0 22px;
        background: #f0f2f5;
        border-color: #f0f2f5;
    }

    .revalidation-success__close:focus {
        background: #f0f2f5;
        border-color: #f0f2f5;
    }

    .revalidation-success__close:hover {
        background-color: #c5cdd8;
        border-color: #c5cdd8;
    }

    .form_question .col-sm-12 {
        margin-top: 15px;
    }

    .department-table {
        font-size: 1rem;
        font-family: "Segoe UI", "Mulish", -apple-system, system-ui, BlinkMacSystemFont, Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        color: #000000;
    }

    .department-table__block {

    }

    .department-table__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 60px;
        border-bottom: 1px solid #EBEBEB;
    }

    .department-table__row:last-child {
        border-bottom-color: transparent;
    }

    .department-table__cell {
        flex-grow: 1;
        flex-basis: 25%;
    }

    .department-table__cell--title {
        flex-basis: 50%;
        cursor: pointer;
    }

    @media screen and (max-width: 479px) {
        .department-table__cell--title {
            flex-basis: 40%;
        }
    }

    .department-table__cell--header-title {
        font-weight: bold;
        text-transform: uppercase;
        cursor: pointer;
    }

    .department-table__cell--questions {
        color: #A4ADBA;
        font-weight: 600;
    }

    .department-table__cell--switch {
        position: relative;
        height: 34px;
    }

    .attestation-switch {
        position: absolute;
        right: 0;
        width: 86px;
        border-radius: 17px;
        cursor: pointer;
        background-color: #F8F8F8;
    }

    .attestation-switch * {
        pointer-events: none;
    }

    .attestation-switch:hover,
    .attestation-switch.open {
        width: 270px;
    }

    .attestation-switch.open * {
        pointer-events: auto;
    }

    .attestation-switch.warning {
        background-color: #FFF7E3;
    }

    .attestation-switch.open {
        z-index: 5;
        border-radius: 11px;
        background-color: #F8F8F8;
    }

    .attestation-switch:after {
        position: absolute;
        top: 11px;
        right: 15px;
        width: 8px;
        height: 8px;
        border-bottom: 1px solid #B5B5B5;
        border-right: 1px solid #B5B5B5;
        transform: rotate(45deg);
        content: '';
    }

    .attestation-switch.open .attestation-switch:after {
        top: 15px;
        transform: rotate(-135deg);
    }

    .attestation-switch__item {
        display: none;
        align-items: center;
        height: 34px;
        padding: 0 17px;
    }

    .attestation-switch.open .attestation-switch__item:first-child {
        border-bottom: 1px solid #E0E0E0;
    }

    .attestation-switch.done .attestation-switch__item.done,
    .attestation-switch.warning .attestation-switch__item.warning,
    .attestation-switch.open .attestation-switch__item {
        display: flex;
    }

    .attestation-switch__done-icon {
        position: relative;
        display: block;
        width: 16px;
        height: 16px;
        margin: 0;
        border: none;
        border-radius: 2px;
    }

    .attestation-switch__done-icon:after {
        position: absolute;
        content: "";
        display: block;
        width: 7px;
        height: 14px;
        border: 1px solid #57BD4E;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        top: 0;
        right: 0;
        bottom: 0;
        left: 5px;
        font-weight: 900;
        font-size: 10px;
        color: #fff;
        line-height: 18px;
        text-align: center;
    }

    .attestation-switch__arrow-down {
        font-size: 1.5rem;
        color: #B5B5B5;
    }

    .attestation-switch__warning-icon {
        display: block;
        width: 17px;
        height: 15px;
        background-size: 17px 15px;
        background-image: url('~assets/img/favicons/warning-icon.svg');
    }

    .attestation-switch__txt {
        display: none;
        margin-left: 12px;
        font-size: 0.875rem;
        line-height: 19px;
        color: #000000;
    }

    .attestation-switch:hover .attestation-switch__txt,
    .attestation-switch.open .attestation-switch__txt {
        display: block;
    }

    .department-table__badge {
        display: inline;
        padding: 3px 5px;
        font-weight: 600;
        font-size: 1rem;
        background-color: #FBC04F;
        border-radius: 4px;
        color: #000000;
    }

    @media screen and (max-width: 479px) {
        .department-table__badge {
            font-size: 12px;
        }
    }

    .setting-question__ttl {
        font-size: 1.25rem;
        line-height: 22px;
        font-weight: 600;
        color: #000000;
    }

    @media screen and (max-width: 479px) {
        .setting-question__ttl {
            font-size: 18px;
        }
    }

    .setting-question__ttl .number {
        margin-left: 3px;
        font-weight: normal;
        color: #AAAAAA;
    }

    @media screen and (max-width: 479px) {
        .question-form-wrap_setting .question-form-header {
            padding-left: 25px;
            padding-right: 25px;
        }
    }

    .question-form-wrap_setting .close-question-form {
        top: -10px;
        right: -45px;
        font-size: 40px;
    }

    @media screen and (max-width: 900px) {
        .question-form-wrap_setting .close-question-form {
            top: 0;
            right: 0;
        }
    }

    .question-form-wrap_setting .questions__add-button {
        margin-top: -7px;
        padding: 11px 20px;
        height: 40px;
        font-size: 1rem;
        color: #000;
        border-color: #FBC04F;
        background-color: #fff;
    }

    @media screen and (max-width: 479px) {
        .question-form-wrap_setting .questions__add-button {
            padding: 11px 10px;
        }
    }

    .setting-question__title {
        margin-top: 32px;
        font-weight: 600;
        font-size: 13px;
        line-height: 22px;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        color: #AAA;
    }

    .setting-question__title:first-child {
        margin-top: 0;
    }

    .setting-question__txt {
        width: 80%;
    }

    .setting-question__item {
        display: flex;
        justify-content: space-between;
        padding: 17px 0;
        border-bottom: 1px solid #EBEBEB;
        font-size: 1rem;
        line-height: 22px;
        color: #000;
    }

    .setting-question__item.locked .setting-question__txt {
        color: #AAA;
    }

    .question-form-wrap_setting .question-form-body {
        overflow-y: auto;
        max-height: 70vh;
    }

    .setting-question__buttons {
        white-space: nowrap;
    }

    .setting-question__button {
        display: inline-block;
        width: 27px;
        height: 27px;
        line-height: 25px;
        margin-left: 10px;
        border-radius: 50%;
        text-align: center;
        background-color: transparent;
        transition: background-color 0.25s;
    }

    .setting-question__button.edit:hover {
        background-color: #FBC357;
    }

    .setting-question__button.delete:hover {
        background-color: #FC5246;
    }

    .setting-question__button.edit:hover svg,
    .setting-question__button.delete:hover svg {
        fill: #fff;
    }

    .setting-question__button svg {
        fill: #ccc;
    }

    .tooltip_templates {
        display: none;
    }

    .tooltipster-base.dark-theme .tooltipster-box {
        background: rgba(31, 31, 31, 0.8);
        border: none;
    }

    .tooltipster-base.dark-theme .tooltipster-content {
        padding: 10px 15px;
        font-size: 0.875rem;
        line-height: 19px;
        font-weight: 300;
        text-align: center;
    }

    .tooltipster-base.dark-theme .tooltipster-arrow-background {
        top: 2px;
        border-width: 8px;
        border-top-color: rgba(31, 31, 31, 0.8) !important;
    }

    .tooltipster-base.dark-theme .tooltipster-arrow-border {
        border: none;
    }

    .questions__filter {
        width: 200px;
        margin-right: 25px;
    }

    @media (max-width: 1100px) {
        .block.questions .block-title {
            width: 100%;
            margin-bottom: 20px;
        }
    }

    .modal {
        padding: 0 !important;
    }

    .modal-body--empty {
        height: 100vh;
    }

    .modal-body__empty-message {
        position: relative;
        top: 50%;
        color: #999;
        text-align: center;
        transform: translateY(-70%);
    }

    .swal2-modal.custom-popup .swal2-title {
        display: flex !important;
        flex-direction: column;
        align-items: center;
    }

    .swal2-modal.custom-popup .swal2-title-right .custom-checkbox {
        margin-top: 10px;
    }

</style>
